import { APPOINTMENT_REQUEST_CALENDAR, CLOSE, SCREEN } from '../../utils/constants';
import {
    originProcedures,
    providers,
    scheduling,
    setProcedures,
    locations,
    phoneToCall,
    setProviderId,
    setProviders,
    originProviders,
    setProcedureSelectionType,
    form,
    setLocationId,
    setIsInstantQuote,
    setIsBookAppointment,
    pagesDom,
    removeLastPagesDom,
    addPagesDom,
    resetPagesDom,
    setSurgicalOption,
    providerId,
    surgicalOption,
    nameScreenDynamic,
    setSelectedGender,
    leadInfo,
    setSelectedLocation,
    selectedLocation,
    procedures,
    isInInstantQuote,
    showMaleFemalePage,
    setDataSlot,
    showBodyAreaSelection,
    selectedGender,
    redirectUrl,
    eventPayload,
    staffId,
    setAccessToken,
    procedureIdSelected,
    setPatientSelected,
    setSelectedProcedures,
    selectedProcedures,
    setStaffProcedures,
    isDesktopVer,
    appointmentChannelId,
    setProcedureIdSelected,
    setStaffId,
    setProceduresOfAppointmentChannel,
    proceduresOfAppointmentChannel,
} from '../../utils/variables';

import { removeFullName } from '../actions/fullNameActions';
import { removeEmail } from '../actions/emailActions';

import { updateLeadStep } from "../../services/leadServices";

/* Components */
import { loadCategories } from '../../views/components/category';
import { loadProcedures } from '../../views/components/procedures';

/* Actions */
import { setTextForServicesButton } from '../actions/browseServicesActions';
import {
    clearCustomFields,
    clearInputsConsult,
    clearLocationDropdown,
    clearProcedureDropdown,
    clearProviderDropdown,
    toggleSelectProcedure,
    toggleSelectProvider,
} from '../actions/consultActions';
import {
    clearAllWidgetIframe,
} from '../actions/iframeActions';
import { removeAllProcedures } from '../actions/procedureActions';
import { removeAllSelectedLocation } from '../actions/locationActions';
import {
    removeAllSelectProvider,
    removeHiddenClassInProvider,
    renderContinueBtn,
} from '../actions/providerActions';
import { toggleLoading, toggleLoadingIframe } from '../actions/loading';
import { clearInputsAskQs } from '../actions/askQuestionActions';
import { loadSelectSearchProcedure } from '../../views/components/selectSearchProcedure';
import { loadProvidersDropdown } from '../../views/components/providersDropdown';
import { resetPageSelectTime } from '../actions/selectTimeActions';
import { resetPageRegisterNewPatientsFormData } from '../actions/registerNewPatientActions';
import { removePhone } from '../actions/phoneActions';
import { preSelectProcedure, removeStyleSurgicalOptionSelected } from './surgicalListeners';
import { selectGenderListeners } from './selectGenderListeners';
import { fetchProcedureByStaff, filterProcedures, handleProviderContinueBtn, handleSelectProvider, providerListeners, renderSelecTime } from './providersListeners';
import { loadProviderList } from '../../views/pages/providers';
import { renderbodySelectionUI } from '../actions/bodyAreaSelectionAction';
import { bodyAreaSelectionListeners } from './bodyAreaSelectionListeners';
import { patientListeners } from './patientListeners';
import { getProceduresStaffByProcedureIds } from '../../services/procedureServices';
import { handleLocationPage } from './locationListeners';
import { renderStaffInfoHeader } from '../actions/InfoStaffAppointment';

export var servicesScreen = '';

export function params(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

export function refresh() {
    /* Close all opening popup in all services screen */
    document
        .querySelectorAll('.popup__open')
        .forEach((i) => i.classList.remove('popup__open'));

    /* Hide all background showed when popup opening */
    document
        .querySelectorAll('.popup__background')
        .forEach((i) => i.classList.remove('displayed'));

    document
        .querySelectorAll('.move-to-right')
        .forEach((i) => i.classList.remove('move-to-right'));

    /* Turn off all loading */
    toggleLoading(false);
    toggleLoadingIframe(false);

    /* clear all iframe screen */
    clearAllWidgetIframe();

    /* Reset procedures and providers to origin data */
    setProcedures(originProcedures);
    setProviders(originProviders);

    /* Reset data in dropdowns */
    loadSelectSearchProcedure(originProcedures);
    if (providers.length > 1) {
        loadProvidersDropdown(originProviders);
    }

    /* Remove selected providers in providers selection screen */
    removeAllSelectProvider();

    /* Remove selected procedures in all services screen */
    removeAllProcedures();

    /* Remove selected location in Location selection screen */
    removeAllSelectedLocation();

    /* Clear inputs in Request consultation screen*/
    clearInputsConsult();

    /* Clear inputs in ask question screen */
    clearInputsAskQs();

    /* Clear dropdowns in Request consultation screen */
    clearLocationDropdown();
    clearProcedureDropdown();
    clearProviderDropdown();

    clearCustomFields();

    removeHiddenClassInProvider();
    setIsInstantQuote(false);

    document.querySelector('.surgical-option__btn')?.classList.add('disabled');
    removeStyleSurgicalOptionSelected(surgicalOption);
    setSurgicalOption(null);

    document
        .querySelector('.consultSummary__container')
        ?.classList?.remove('show-consult-summary-question');

    document
        .querySelector('.endScreen__container')
        ?.classList?.remove('show-the-end-screen');

    document
        .querySelectorAll('.book-appointment')
        .forEach((i) => i.classList.remove('book-appointment'));
    document
        .querySelectorAll('.ask-question__btn')
        .forEach((i) => (i.textContent = 'Ask a question'));
    document
        .querySelectorAll('.providerScheduling__container .schedule__option')
        .forEach((j) => j.classList.remove('selected'));
    document
        .querySelector('.seeServices__container')
        ?.classList.remove('procedure-in-booking');
    document
        .querySelectorAll('.procedure__price-duration')
        .forEach((j) => j.classList.remove('procedure__price-duration--show'));
    document
        .querySelector('.providers__container')
        ?.classList?.remove('provider-in-booking');
    document.querySelector('.no-preference-provider')?.classList?.add('hidden');

    if (document.querySelector('.phone--input invalid')) {
        document.querySelector('.phone--input invalid').value = '';
    }
    document.querySelector('.phone--input invalid')?.blur();

    if (
        document.querySelector(
            '.requestConsult__container .requestConsult__btn'
        )
    ) {
        document
            .querySelector('.requestConsult__container .requestConsult__btn')
            .classList.remove('disabled');
    }

    if (
        document.querySelector(
            '.requestConsult__container .requestConsult__btn .loader__icon'
        )
    ) {
        document.querySelector(
            '.requestConsult__container .requestConsult__btn .loader__icon'
        ).style.display = 'none';
    }

    document.querySelector('.gender__container .gender--selected')
        ?.classList.remove('gender--selected');
    setSelectedGender(null);

    removeFullName();
    document.querySelector('.email__container .email__valid')?.classList.add('hidden');
    document.querySelector('.email__container .email__invalid')?.classList.add('hidden');

    removeEmail();
    document.querySelector('.fullNames__container .fullName__valid')?.classList.add('hidden');
    document.querySelector('.fullNames__container .fullName__invalid ')?.classList.add('hidden');

    let imagesUploads = document.querySelector('.uploadPhotos__container .imagesUpload');
    if (imagesUploads) {
        imagesUploads.innerHTML = '';
    }

    resetPagesDom();
    setIsBookAppointment(false);
    setDataSlot({});
}

function showScreen(className) {
    const element = document.querySelector(className);
    addPagesDom(element);
    pagesDom[pagesDom.length - 1].style.zIndex = (
        parseInt(pagesDom[pagesDom.length - 2].style.zIndex) + 1
    ).toString();
}

export async function navigate(page) {

    const screen = getClassScreen(page);


    if (form?.disclaimer) {
        const widgetFooter = document.querySelector(`${screen} .widget-footer`)
        const subFooter1 = widgetFooter?.querySelector('.subFooter--1')
        const mainEl = widgetFooter?.closest(".mainMenu__container ") || widgetFooter?.closest(".screen")
        if (mainEl) {
            mainEl?.querySelector('.screen__content')?.style.setProperty('--header-height', `${subFooter1.clientHeight + (90 - 17)}px`)
        }
    }

    if (isDesktopVer || page === SCREEN.NOTFOUND_APPOINTMENT) {
        const container = document?.querySelector(`${screen} .screen__content`)
        container?.style.setProperty('--header-height', '0px')
        container?.querySelector('.title__wrapper')?.classList.add("hidden")
        if (!container?.querySelector('.infoStaff')) {
            renderStaffInfoHeader(null, null, screen.replace('.', ''), true)
        }
    }

    await updateStep(page);

    if (page === SCREEN.THE_END && redirectUrl) {
        window.parent.location.href = redirectUrl;

        return;
    }

    showScreen(screen);
}

export async function updateStep(page) {
    if (!leadInfo?.id) {
        return;
    }

    if (page === SCREEN.UPLOAD_PHOTOS) {
        await updateLeadStep(leadInfo.id, 'pre_upload');
    }

    if (page === SCREEN.THE_END) {
        if (eventPayload && eventPayload?.status === 'request') {
            return;
        }
        await updateLeadStep(leadInfo.id, 'consult', 'true');
    }
}

export function goBack(screen) {
    let page = getClassScreen(screen);
    if (pagesDom.length - 2 > 0) {
        pagesDom[pagesDom.length - 2].style.pointerEvents = 'none';
    }
    if (screen === SCREEN.SEE_ALL_SERVICES) {
        document.querySelector('.seeServices__container .category_wrapper').innerHTML = '';
        document.querySelector('.seeServices__container .procedures__container').innerHTML = '';
    }
    document.querySelector(page).style.zIndex = '1';
    document.querySelector(page).willChange = 'auto';
    removeLastPagesDom();
    pagesDom[pagesDom.length - 1].style.pointerEvents = 'auto';

    /* Current screen is menu option screen */
    if (pagesDom.length === 1) {
        refresh();
    }

    switch (screen) {
        case SCREEN.ASK_QUESTION:
            clearInputsAskQs();
            break;
        case SCREEN.REQUEST_CONSULT:
            clearInputsConsult();
            break;
        case SCREEN.SEE_ALL_SERVICES:
            removeAllProcedures();
            setProcedureSelectionType(form.selection_type);
            break;
        case SCREEN.SELECT_TIME:
            resetPageSelectTime();
            document
                .querySelector(
                    `.providers__list .provider[data-provider-id="${providerId}"]`
                )
                .click();
            break;
        case SCREEN.SURGICAL:
            document
                .querySelector('.validateDigitsContinue__btn')
                .classList.add('disabled');
            setIsBookAppointment(false);
            break;
        case SCREEN.VALIDATE_DIGITS:
            document.getElementById('validateDigits0').value = '';
            document.getElementById('validateDigits1').value = '';
            document.getElementById('validateDigits2').value = '';
            document.getElementById('validateDigits3').value = '';
            break;
        case SCREEN.REGISTER_NEW_PATIENT:
            resetPageRegisterNewPatientsFormData();
            break;
        case SCREEN.PROVIDERS:
            removeHiddenClassInProvider();
            document.querySelector('.providers__container')?.classList?.remove('provider-in-booking');
            document.querySelector('.no-preference-provider')?.classList?.add('hidden');
            break;
        case SCREEN.SELECT_GENDER:
            setIsInstantQuote(false);
            break;
        case SCREEN.PHONE:
            removePhone();
            break;
        default:
            break;
    }
}

export function customFeildGoBackAnimationHandler(pageClass) {
    document.getElementById('consult-summary')?.focus();
    document.querySelector(pageClass).style.zIndex = '1';
    removeLastPagesDom();
}

export function getClassScreen(screen) {
    switch (screen) {
        case SCREEN.DASHBOARD:
            return '.mainMenu__container';
        case SCREEN.ASK_QUESTION:
            return '.askQuestion__container';
        case SCREEN.THE_END:
            return '.endScreen__container';
        case SCREEN.REQUEST_CONSULT:
            return '.requestConsult__container';
        case SCREEN.INSTANT_QUOTE:
            return '.widgetScreen__container.instant-quote';
        case SCREEN.PROCEDURE_ELIGIBILITY:
            return '.widgetScreen__container.procedure-eligibility';
        case SCREEN.SEE_ALL_SERVICES:
            return '.seeServices__container';
        case SCREEN.PROVIDERS:
            return '.providers__container';
        case SCREEN.SERVICES_IFRAME:
            return '.widgetScreen__container.see-services';
        case SCREEN.TOP_QUESTION:
            return '.topQuestion__container';
        case SCREEN.LOCATIONS:
            return '.locations__container';
        case SCREEN.CONSULT_SUMMARY:
            return '.consultSummary__container';
        case SCREEN.CONSULT_SCHEDULING:
            return '.consultScheduling__container';
        case SCREEN.PROVIDER_SCHEDULING:
            return '.providerScheduling__container';
        case SCREEN.SERVICE_SUMMARY:
            return '.serviceSummary__container';
        case SCREEN.INTERESTS:
            return '.interests__container';
        case SCREEN.FULL_NAME:
            return '.fullNames__container';
        case SCREEN.EMAIL:
            return '.email__container';
        case SCREEN.PHONE:
            return '.phone__container';
        case SCREEN.INSTANT_QUOTE_SUMMARY:
            return '.instant_quote_summary__container';
        case SCREEN.SURGICAL:
            return '.surgical__container';
        case SCREEN.VALIDATE_DIGITS:
            return '.validateDigits__container';
        case SCREEN.SELECT_TIME:
            return '.selectTime__container';
        case SCREEN.SELECT_ONLY_DATE:
            return '.selectOnlyDate__container';
        case SCREEN.SELECT_ONLY_TIME:
            return '.selectOnlyTime__container';
        case SCREEN.MAIN_MENU:
            return '.mainMenu__container';
        case SCREEN.REGISTER_NEW_PATIENT:
            return '.register-new-patient__container';
        case SCREEN.APPOINTMENT_SUMMARY:
            return '.appointment-summary__container';
        case SCREEN.APPOINTMENT_CONFIRMED:
            return '.appointment-confirmed__container';
        case SCREEN.UPLOAD_PHOTOS:
            return '.uploadPhotos__container';
        case SCREEN.SELECT_GENDER:
            return '.gender__container';
        case SCREEN.BODY_AREA_SELECTION:
            return '.bodyAreaSelection__container';
        case SCREEN.VERIFY_INFORMATION:
            return '.verifyInformation__container';
        case SCREEN.PATIENTS:
            return '.patients__container';
        case SCREEN.NOTFOUND_APPOINTMENT:
            return '.notfound__container';
        case SCREEN.TEXTAREA_QUESTION:
        case SCREEN.CHECKBOX_QUESTION:
        case SCREEN.TEXT_QUESTION:
        case SCREEN.SELECT_QUESTION:
        case SCREEN.DATE_QUESTION:
        case SCREEN.NUMBER_QUESTION:
        case SCREEN.HEIGHT_QUESTION:
            return `${nameScreenDynamic}`;
        default:
            break;
    }
}

export function pageListeners() {
    /* Select FAQs */
    if (document.querySelector(`#${SCREEN.FAQS}`)) {
        document.querySelector(`#${SCREEN.FAQS}`).onclick = () =>
            openFaqs();
    }

    /* Select Ask A Question */
    if (document.querySelector(`#${SCREEN.ASK_QUESTION}`)) {
        document.querySelector(`#${SCREEN.ASK_QUESTION}`).onclick = () =>
            openAskQuestion();
    }

    /* Select Text Us */
    if (document.querySelector(`#${SCREEN.TEXT_US}`)) {
        document.querySelector(`#${SCREEN.TEXT_US}`).onclick = () =>
            navigate(SCREEN.ASK_QUESTION);
    }

    /* Select instant quote */
    if (document.querySelector(`#${SCREEN.INSTANT_QUOTE}`)) {
        document.querySelector(`#${SCREEN.INSTANT_QUOTE}`).onclick = () =>
            openInstantQuotes(SCREEN.INSTANT_QUOTE);
    }

    /* Select Call */
    if (document.querySelector(`#call.option`)) {
        document.querySelector('#call.option').onclick = () =>
            (window.parent.location.href = `tel:${phoneToCall}`);
    }

    /* Select Book Appointment */
    if (document.querySelector(`#${SCREEN.BOOK_APPOINTMENT}`)) {
        document.querySelector(`#${SCREEN.BOOK_APPOINTMENT}`).onclick = () =>
            openBookAppointment();
    }

    /* Select Custom Option */
    let tempOptionsDom = document.querySelectorAll(`.option`);
    if (tempOptionsDom && tempOptionsDom.length > 0) {
        tempOptionsDom.forEach((item) => {
            if (item.id.includes('custom_option-')) {
                let tempHref = item.querySelector('a').href;
                item.addEventListener('click', () => {
                    window.open(tempHref, '_blank');
                });
            }
        });
    }

    navigateGoBack();
    navigateGoBackOneStep();
}

function navigateGoBack() {
    document.querySelectorAll('.screen .back-arrow').forEach((i) => {
        i.onclick = () => goBack(i.getAttribute('data-screen'));
    });
    document.querySelectorAll('.screen .backOneStep__btn').forEach((i) => {
        i.onclick = () => goBack(i.getAttribute('data-screen'));
    });
}

function navigateGoBackOneStep() {
    document.querySelectorAll('.screen .back-to-before-step').forEach((i) => {
        i.onclick = () => goBack(i.getAttribute('data-screen'));
    });
}

export function openInstantQuote() {
    setAccessToken(form.token)
    setIsInstantQuote(true);
    handleCheckProjectCategory();
}

export function openScheduleConsultation() {
    if (locations.length === 1) {
        const locationConsultElement =
            document.querySelector('#locationConsult');
        if (locationConsultElement) {
            locationConsultElement.value = locations[0]?.name;
        }
        setLocationId(locations[0]?.id);
        document
            .querySelector(
                '.requestConsult__container div[data-form="locationConsult"]'
            )
            ?.classList.remove('invalid');

        document
            .querySelector(
                '.requestConsult__container .locationsDropdown__wrapper'
            )
            ?.classList.add('selected');
    }

    if (providers.length === 1) {
        const id = providers[0].id;

        setProviderId(id);

        const newProcedures = providers.find((j) => j.id === id).procedures;

        loadSelectSearchProcedure(newProcedures);

        clearProcedureDropdown();
    }

    if (!scheduling) {
        loadSelectSearchProcedure(procedures);
        toggleSelectProcedure(CLOSE);
        toggleSelectProvider(CLOSE);
        openBookAppointment();
    } else {
        navigate(SCREEN.INSTANT_QUOTE);
    }
}

export function openFaqs() {
    navigate(SCREEN.TOP_QUESTION);
}

export function openAskQuestion() {
    navigate(SCREEN.ASK_QUESTION);
}

export async function openInstantQuotes(screen) {
    servicesScreen = screen;
    setIsInstantQuote(true);
    if (locations.length > 1) {
        navigate(SCREEN.LOCATIONS);
    } else if (locations.length <= 0) {
        navigate(SCREEN.LOCATIONS);
        document.querySelector(
            '.locations__container .title__wrapper--instant .title__content'
        ).textContent = 'No location found';
    } else {
        handleCheckProjectCategory();
    }
}

export function directToAllServices(button, procedures) {
    setTextForServicesButton(button);

    loadCategories(procedures);
    loadProcedures(procedures);

    navigate(SCREEN.SEE_ALL_SERVICES);
}

export async function openBookAppointment() {
    const lengthServiceType = Object.keys(form.service_types).length;
    setIsBookAppointment(true);
    setAccessToken(form.token)
    let appointmentRequestType = appointmentChannelId ? "calendar" : form.appointment_channel?.appointment_request_type

    if (form?.appointment_channel?.procedures?.length) {
        const proceduresOnlineBooking = form?.appointment_channel?.procedures?.filter(p => p?.online_booking)
        setProceduresOfAppointmentChannel(proceduresOnlineBooking)
    }

    if (appointmentChannelId && (!proceduresOfAppointmentChannel?.length || !form?.appointment_channel?.staffs?.length)) {
        navigate(SCREEN.NOTFOUND_APPOINTMENT)
        return;
    }

    if (lengthServiceType >= 2 || appointmentChannelId) {
        if (staffId) {
            const checkStaffId = form?.staff?.findIndex(staff => staff.id === +staffId);
            if (checkStaffId >= 0) {
                await fetchProcedureByStaff(+staffId);
            }
        }

        if (appointmentRequestType !== APPOINTMENT_REQUEST_CALENDAR && procedureIdSelected) {
            if (procedureIdSelected) {
                preSelectProcedure();
            }
            if (staffId) {
                loadSelectSearchProcedure(procedures);
            }
            const foundProcedure = procedures?.find(i => i?.id === +procedureIdSelected)
            if (foundProcedure) {
                setSurgicalOption(Boolean(foundProcedure.surgical));
            }
            navigate(SCREEN.REQUEST_CONSULT);
        } else {
            if (!procedureIdSelected && proceduresOfAppointmentChannel) {
                setProcedureIdSelected(proceduresOfAppointmentChannel[0]?.id);
            }

            if (appointmentChannelId && form?.appointment_channel?.visitor_can_choose_the_staff) {
                if (locations?.length === 1) {
                    setSelectedLocation(locations[0]?.id);
                    handleLocationPage();
                } else {
                    navigate(SCREEN.LOCATIONS);
                }

                return
            }

            if (!staffId && form?.appointment_channel?.staffs) {
                setStaffId(form?.appointment_channel?.staffs[0]?.id);
            }

            if (staffId && procedureIdSelected) {
                handleProcedureSelection()

            } else {
                navigate(SCREEN.SURGICAL);
            }
        }

    } else {
        let service = Object.values(form.service_types)[0];

        if (appointmentChannelId && form?.appointment_channel?.visitor_can_choose_the_staff) {
            if (locations?.length === 1) {
                setSelectedLocation(locations[0]?.id);
                handleLocationPage();
            } else {
                navigate(SCREEN.LOCATIONS);
            }

            return
        }

        if (procedureIdSelected) {
            preSelectProcedure();
        }
        if (staffId) {
            const checkStaffId = form?.staff?.findIndex(staff => staff.id === +staffId);
            if (checkStaffId >= 0) {
                await fetchProcedureByStaff(+staffId);
                loadSelectSearchProcedure(procedures);
            }
        }

        if (service === 'surgical') {
            if (appointmentRequestType === APPOINTMENT_REQUEST_CALENDAR) {
                if (appointmentChannelId) {
                    handleProcedureSelection()
                } else {

                    patientListeners();
                    navigate(SCREEN.PATIENTS);
                }
            } else {
                navigate(SCREEN.REQUEST_CONSULT);
            }
        } else {
            if (appointmentRequestType === APPOINTMENT_REQUEST_CALENDAR) {
                if (appointmentChannelId) {
                    handleProcedureSelection()
                } else {
                    patientListeners();
                    navigate(SCREEN.PATIENTS);

                }
            } else {
                navigate(SCREEN.REQUEST_CONSULT);
            }
        }
    }
}

const handleProcedureSelection = () => {
    let foundProcedure = procedures.find(p => p?.id === +procedureIdSelected)
    if (!foundProcedure && !appointmentChannelId) {
        navigate(SCREEN.SURGICAL);
    } else {
        if (!foundProcedure && appointmentChannelId) {
            foundProcedure = proceduresOfAppointmentChannel[0]
        }
        setSurgicalOption(Boolean(foundProcedure.surgical));
        setPatientSelected(foundProcedure.type);
        setSelectedProcedures([foundProcedure.id]);
        handleClickContinueServiceBtn()
    }
};

const handleClickContinueServiceBtn = async () => {
    if (staffId) {
        const foundProvider = providers?.find(i => i?.id === +staffId)
        setSelectedLocation(foundProvider?.location_id)
        handleSelectProvider(null, +staffId || foundProvider?.id);
        handleProviderContinueBtn()
    } else {
        if (selectedProcedures.length > 0) {
            let res = await getProceduresStaffByProcedureIds(
                selectedProcedures
            );
            setStaffProcedures(res?.data?.data || []);
        }
        if (locations?.length === 1) {
            setSelectedLocation(locations[0]?.id);
            handleLocationPage();
        } else {
            navigate(SCREEN.LOCATIONS);
        }
    }
}

export function doFoCus(targetInput) {
    const fakeInput = document.createElement('input');
    fakeInput.setAttribute('type', 'tel');
    fakeInput.setAttribute('inputmode', 'tel');
    fakeInput.style.position = 'absolute';
    fakeInput.style.opacity = 0;
    fakeInput.style.height = 0;
    fakeInput.style.fontSize = '16px';

    document.body.prepend(fakeInput);
    fakeInput.focus();

    setTimeout(() => {
        targetInput.focus();
        fakeInput.remove();
    }, 400);
}

function handleCheckProjectCategory() {
    if (showMaleFemalePage) {
        selectGenderListeners();
        navigate(SCREEN.SELECT_GENDER);
    } else {
        if (showBodyAreaSelection) {
            renderbodySelectionUI(selectedGender || 'non-binary');
            bodyAreaSelectionListeners();
            navigate(SCREEN.BODY_AREA_SELECTION);

        } else {
            staffId ? handleSelectProvider() : handleCheckProviders();
        }
    }
}

export function handleCheckProviders() {
    if (providers.length > 0 && staffId === 0) {
        setSelectedLocation(locations[0].id);
        loadProviderList(
            providers.filter(
                (provider) => provider.location_id === selectedLocation
            )
        );
        renderContinueBtn();
        providerListeners();
        navigate(SCREEN.PROVIDERS);
    } else {
        if (isInInstantQuote) {
            loadCategories(filterProcedures(procedures));
            loadProcedures(filterProcedures(procedures));
            navigate(SCREEN.SEE_ALL_SERVICES);
        }
    }
}
